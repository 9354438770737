.admin-fileselect {
    &.k-upload {

        .k-upload-files {
            max-height: none;

            .k-file {
                padding: 0;

                .admin-file {
                    padding: $upload-item-padding-x calc(#{$upload-item-padding-y} + #{$upload-progress-thickness});
                    align-items: center;
                    width: 100%;
                    display: flex;

                    &.active {
                        background-color: $primary;
                        color: contrast-wcag($primary);
                    }

                    &.error {
                        background-color: lighten($color: $danger, $amount: 5);
                        // pointer-events: none;
                        // cursor: not-allowed;
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &__icon {
                        height: 100%;
                        margin-right: $upload-item-padding-x;
                    }

                    &__text {
                        width: 100%;
                        word-break: break-all;
                    }

                    &__actions {
                        display: flex;
                    }
                }
            }
        }
    }
}
