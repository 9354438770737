.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    flex-shrink: 0;

    font-size: 1rem;
    &--primary {
        color: $primary;
    }

    &--danger {
        color: $danger;
    }


    &--action {
        position: relative;

        @extend %btn-reset;
        &:disabled {
            cursor: not-allowed;
        }


        &--primary {
            color: $primary;
            @include interactive-effects($primary, 'color');
        }

        &--danger {
            color: $danger;
            @include interactive-effects($danger, 'color');
        }

        &--secondary {
            color: $secondary;
            @include interactive-effects($secondary, 'color');
        }
    }


    &.drag-handle {
        @extend %btn-reset;

        color: #C4C4C4;
        &:hover {
            opacity: 0.75;
        }

        &:focus {
            opacity: 0.5;
        }
    }


    // .ng-fa-icon {
    //     display: flex;
    //     .fa-eye {
    //         width: rem(20px);
    //     }
    // }
}


.icon-container {
    display: flex;
    .icon {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }
}


.icon-information-circle {
    display: inline-block;

    width: rem(16px);
    height: rem(16px);

    background-image: url(/assets/icon/information-circle.svg);
    @extend %bgi-responsive;
    &:hover {
        opacity: 0.75;
    }

    &:focus {
        opacity: 0.5;
    }
}
