.not-found {
    @extend %flex-center-center;
    flex-direction: column;
    pointer-events: none;
    color: #999999;
    max-width: 20rem;
    margin: auto;

    .icon {
        .k-icon {
            margin-bottom: 2rem;
            font-size: rem(72px);
            color: #CCCCCC;
        }
    }

    &-title {
        font-weight: 500;
        font-size: 2rem;
        letter-spacing: 0.02em;

        &+.not-found-text {

            margin-top: 1.25rem;
        }
    }

    &-text {
        font-weight: 500;
        font-size: 1rem;
        text-align: center;
    }

    &--sm {
        font-size: 1rem;

        .icon {
            margin-bottom: 1rem;
            font-size: 2rem;
        }
    }
}
