.k-treeview {

    &__truncate {
        overflow: visible;

        .k-item {
            word-break: break-all;
            white-space: break-spaces;
        }
    }
}
