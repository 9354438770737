.k-icon {
    &.k-i {
        &-danger {
            color: $error;
        }

        &-primary {
            color: $primary;
        }


        &-size {
            &-1 {
                font-size: 0.5rem
            }

            &-2 {
                font-size: 0.75rem
            }

            &-3 {
                font-size: 1rem
            }

            &-4 {
                font-size: 1.25rem;
            }

            &-5 {
                font-size: 1.5rem;
            }

            &-6 {
                font-size: 2rem;
            }
        }

    }
}
