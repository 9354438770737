%flex-container {
    display: flex;
    flex-direction: column;

    height: 100%;
    min-height: 0;
}


%flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


%flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}