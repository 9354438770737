p.length-counter-wrapper {
    &.invalid {
        color: $danger;
    }
    position: relative;
    opacity: 0.5;
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 12px;
    top: 2px;
    right: 2px;
}
