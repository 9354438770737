html {
    font-size: $html-font-size;
}


body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    letter-spacing: 0.02px;
}
