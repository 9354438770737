$font-family-base: 'Roboto',
sans-serif;
$line-height-base: 1.3;

$body-bg: #FBFCFD;
$body-color: rgba(#000, 0.87);

$primary: #1976d2;
$secondary: #e0e0e0;
$danger: $error;



$border-radius: 4px;
$border-color: #EBEBEB;


// GRID
$grid-gutter-width: 16px;


// MODAL
// $modal-xl: 1180px;
// $modal-inner-padding: 0 1.5rem;
// $modal-title-line-height: 30px;
// $modal-header-padding: 1.5rem 1.5rem 1rem;
// $modal-footer-border-width: 0;


// BUTTON
$btn-padding-x: 1.5rem;
$btn-font-size: 0.875rem;
$btn-font-weight: 500;


// INPUT
$input-height: 2.5rem;
$input-padding-y: 0.563rem;
$input-border-color: #D0D0D0;
$input-border-radius: 2px;
$input-color: #000;
$input-placeholder-color: #999;
$input-disabled-bg: #f9f9f9;


// DROPDOWN
$dropdown-spacer: 0.5rem;
// $dropdown-padding-y: 0;
$dropdown-item-padding-y: 0.75rem;
$dropdown-bg: #fff;
$dropdown-border-color: $border-color;
$dropdown-link-color: $body-color;
$dropdown-link-hover-bg: #DEEFFD;


// NAV-TABS
$nav-tabs-link-active-bg: $light-primary;
$nav-tabs-border-color: $border-color;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $primary;


// TYPOGRAPHY
$headings-margin-bottom: 0;
$h6-font-size: 0.875rem;


// PADDING
$spacer: 1rem;
