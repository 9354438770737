@import 'styles/settings/index.scss';
// How to customize NgBootstrap color theme: https://getbootstrap.com/docs/4.0/getting-started/theming/
// NgBootstrap must always be after @import 'settings.index.scss' ( to prevent using default NgBootstrap variables )
@import "node_modules/bootstrap/scss/bootstrap";
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import 'styles/tools/index.scss';
@import 'styles/generic/index.scss';
@import 'styles/elements/index.scss';
@import 'styles/objects/index.scss';
@import 'styles/components/index.scss';
@import 'styles/utilities/index.scss';
// TEMP
mark {
    background: rgba(33, 150, 243, 0.9);
    color: white;
}
