.contributor {
    display: inline-flex;
    align-items: flex-start;
    &__img {
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        &:not(:last-child) {
            margin-right: 12px;
        }

        img,
        .icon {
            width: 100%;
            height: 100%;
            
            border-radius: 50%;

            background: #DFDFDF;
        }

        img {
            object-fit: contain;
        }
        
        .icon {
            color: $input-placeholder-color;
            font-size: 1rem;
        }

        p {
            font-size: 15px;
        }
    }


    &__info {
        align-self: center;
        &-priority {
            font-weight: 500;
        }

        &-secondary {
            color: rgba(#000, .5);
            font-size: rem(14px);
        }
    }
}