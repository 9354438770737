%custom-scroll {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: #D8D8D8;
        border-radius: 4px;
        &:hover {
            background-color: darken(#D8D8D8, 15%);
        }
    }
}


%overflow {
    overflow: auto;
    @extend %custom-scroll;

    max-height: 100%;
    margin: -2px;
    padding: 2px;
}


%overflow-y {
    overflow-y: auto;
    overflow-x: hidden;
    @extend %custom-scroll;

    max-height: 100%;
    margin: -2px;
    padding: 2px;
}


%overflow-x {
    overflow-y: hidden;
    overflow-x: auto;
    @extend %custom-scroll;

    margin: -2px;
    padding: 2px;
}