// TODO: refactor to mixin

// Success button
$success-button-bg: $success !default;
$success-button-text: contrast-wcag($success-button-bg) !default;
$success-button-border: $success-button-bg !default;
$success-button-gradient: null !default;
$success-button-hovered-bg: try-darken($success-button-bg, 7.5%) !default;
$success-button-hovered-text: contrast-wcag($success-button-hovered-bg) !default;
$success-button-hovered-border: try-darken($success-button-border, 10%) !default;
$success-button-hovered-gradient: null !default;
$success-button-pressed-bg: try-darken($success-button-bg, 10%) !default;
$success-button-pressed-text: contrast-wcag($success-button-pressed-bg) !default;
$success-button-pressed-border: try-darken($success-button-border, 12.5%) !default;
$success-button-pressed-gradient: null !default;
$success-button-pressed-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, .06) !default;
$success-button-focused-shadow: 0 0 0 3px rgba($success-button-border, .5) !default;

// Danger button
$danger-button-bg: $danger !default;
$danger-button-text: contrast-wcag($danger-button-bg) !default;
$danger-button-border: $danger-button-bg !default;
$danger-button-gradient: null !default;
$danger-button-hovered-bg: try-darken($danger-button-bg, 7.5%) !default;
$danger-button-hovered-text: contrast-wcag($danger-button-hovered-bg) !default;
$danger-button-hovered-border: try-darken($danger-button-border, 10%) !default;
$danger-button-hovered-gradient: null !default;
$danger-button-pressed-bg: try-darken($danger-button-bg, 10%) !default;
$danger-button-pressed-text: contrast-wcag($danger-button-pressed-bg) !default;
$danger-button-pressed-border: try-darken($danger-button-border, 12.5%) !default;
$danger-button-pressed-gradient: null !default;
$danger-button-pressed-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, .06) !default;
$danger-button-focused-shadow: 0 0 0 3px rgba($danger-button-border, .5) !default;

.k-button {

    // Success button
    &.k-success {
        @include fill($success-button-text,
            $success-button-bg,
            $success-button-border,
            $success-button-gradient);

        // Hover state
        &:hover,
        &.k-state-hover {
            @include fill($success-button-hovered-text,
                $success-button-hovered-bg,
                $success-button-hovered-border,
                $success-button-hovered-gradient);
        }

        // Focused state
        &:focus,
        &.k-state-focused {
            box-shadow: $success-button-focused-shadow;
        }

        // Active state
        &:active,
        &.k-state-active {
            @include fill($success-button-pressed-text,
                $success-button-pressed-bg,
                $success-button-pressed-border,
                $success-button-pressed-gradient);
            box-shadow: $success-button-pressed-shadow;
        }

        // Selected state
        &.k-state-selected {
            @include fill($success-button-pressed-text,
                $success-button-pressed-bg,
                $success-button-pressed-border,
                $success-button-pressed-gradient);
            box-shadow: $success-button-pressed-shadow;
        }

        // Disabled state
        // &:disabled,
        // &.k-state-disabled {}
    }

    // Danger button
    &.k-danger {
        @include fill($danger-button-text,
            $danger-button-bg,
            $danger-button-border,
            $danger-button-gradient);

        // Hover state
        &:hover,
        &.k-state-hover {
            @include fill($danger-button-hovered-text,
                $danger-button-hovered-bg,
                $danger-button-hovered-border,
                $danger-button-hovered-gradient);
        }

        // Focused state
        &:focus,
        &.k-state-focused {
            box-shadow: $danger-button-focused-shadow;
        }

        // Active state
        &:active,
        &.k-state-active {
            @include fill($danger-button-pressed-text,
                $danger-button-pressed-bg,
                $danger-button-pressed-border,
                $danger-button-pressed-gradient);
            box-shadow: $danger-button-pressed-shadow;
        }

        // Selected state
        &.k-state-selected {
            @include fill($danger-button-pressed-text,
                $danger-button-pressed-bg,
                $danger-button-pressed-border,
                $danger-button-pressed-gradient);
            box-shadow: $danger-button-pressed-shadow;
        }

        // Disabled state
        // &:disabled,
        // &.k-state-disabled {}
    }

    // Button with loader
    &.k-loader {
        position: relative;
        overflow: hidden;

        kendo-loader {
            @extend %flex-center-center;

            position: absolute;
            width: 100%;
            height: 100%;
            background-color: inherit;
            z-index: 1;
        }
    }
}
