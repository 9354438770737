$table-flex-border: 1px solid $border-color;


.table-flex {
    &__head,
    &__row {
        display: flex;

        min-height: rem(68px);
    }


    &__head {
        .table-flex__cell {
            align-items: center;
            justify-content: center;
            
            background-color: #F9F9F9;
            border-top: $table-flex-border;
            border-left: $table-flex-border;
            border-bottom: $table-flex-border;

            color: $primary;
            font-weight: 500;
            &:last-child {
                border-right: $table-flex-border;
            }
        }
    }


    &__cell {
        display: flex;
        flex-wrap: wrap;

        padding: rem(14px);

        border-left: $table-flex-border;
        border-bottom: $table-flex-border;
        &:last-child {
            border-right: $table-flex-border;
        }

        &.cell-fluid {
            flex-grow: 1;
        }
    }
}
