.k-drawer-link {
    &.k-drawer-link-active {
        color: #fff;
        background-color: $primary;
    }
}

.k-drawer-expanded {

    .k-level {
        &-0 {
            padding-left: 1rem !important;
        }

        &-1 {
            padding-left: 2rem !important;
        }

        &-2 {
            padding-left: 3rem !important;
        }

        &-3 {
            padding-left: 4rem !important;
        }

        &-4 {
            padding-left: 5rem !important;
        }

        &-5 {
            padding-left: 6rem !important;
        }

        &-6 {
            padding-left: 7rem !important;
        }
    }
}
