.panel {
    display: flex;
    align-items: center;

    margin-bottom: 1.5rem;

    &__content {
        display: flex;
        align-items: center;

        &:not(:last-child),
        .h1:not(:last-child) {
            margin-right: 1rem;
        }

        .icon--action {
            padding-right: 1rem;
        }

        .h1 {
            margin-bottom: 0;

            font-size: rem(26px);
            line-height: rem(30px);
            letter-spacing: 0.15px;
        }
    }


    .status {
        &:not(:last-child) {
            margin-right: 1rem;
        }
    }


    &__actions {
        display: flex;
        align-items: center;

        flex-shrink: 0;
        min-width: 300px;
        margin-left: auto;

        .panel__action {
            &:not(:last-child) {
                margin-right: rem($m--md);
            }


            .dropdown-menu {
                max-height: initial;
            }
        }
    }
}
