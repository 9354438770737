p {
    margin-bottom: 0;
}


ul,
ol {
    margin-bottom: 0;
    padding-left: 0;

    list-style: none;
}