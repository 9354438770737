.pressed-to-edge {
    margin-right: -1rem;
    padding-right: 1rem;
}


.overflow {
    @extend %overflow;
}


.overflow-y {
    @extend %overflow-y;
}


.overflow-x {
    @extend %overflow-x;
}