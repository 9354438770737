// KENDO

$border-radius: 0.25rem;
// $accent: #007bff;
$secondary: #e4e4e4;
// $info: #17a2b8;
$success: #28a745;
// $warning: #ffc107;
$error: #dc3545;
// $body-bg: #ffffff;
// $body-color: #292b2c;
// $component-bg: #ffffff;
// $component-color: #292b2c;
// $card-cap-bg: #f7f7f9;
// $card-cap-color: #292b2c;
// $series-a: #0275d8;
// $series-b: #5bc0de;
// $series-c: #5cb85c;
// $series-d: #f0ad4e;
// $series-e: #e67d4a;
// $series-f: #d9534f;


// FONT
// $font-size: 1.25rem;
// $line-height-base:
// $drawer-item-font-size: 1.25rem;

// Switch
// $switch-track-size: 14px;



// HEADER
$header-bg: #fff;


// DRAWER
$drawer-selected-text: inherit;
$drawer-selected-bg: inherit;
$drawer-selected-hover-text: inherit;
$drawer-selected-hover-bg: inherit;



// MODAL
$dialog-buttongroup-padding-x: 1rem;
$window-inner-padding-x: 0;


// CARD
// $card-subtitle-text: #fff;


// BUTTONS
$input-btn-padding-y: .375rem !default;
